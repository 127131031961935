import React, {
  createContext,
  useContext,
  useState,
  useRef,
  useEffect
} from "react";

const MediaPlayerContext = createContext();

export const useMediaPlayer = () => useContext(MediaPlayerContext);

export const MediaPlayerProvider = ({ children, currentTracks }) => {
  const queryTrackIndex = new URLSearchParams(window.location.search).get(
    "trackIndex"
  );
  const trackIndexFromQuery = isNaN(queryTrackIndex) ? 0 : +queryTrackIndex;

  const [tracks, setTracks] = useState(currentTracks);
  const [currentTrackIndex, setCurrentTrackIndex] =
    useState(trackIndexFromQuery);
  const [playing, setPlaying] = useState(false);
  const [loop, setLoop] = useState(false);
  const audioRef = useRef(new Audio());
  const trackSource = useRef();
  const audioContext = useRef(
    new (window.AudioContext || window.webkitAudioContext)()
  );
  const initialized = useRef(false);

  useEffect(() => {
    setTracks(currentTracks);
  }, [currentTracks]);

  useEffect(() => {
    audioRef.current.src = tracks[currentTrackIndex].audioSrc;

    const handleSongEnd = () => {
      if (currentTrackIndex < tracks.length - 1) {
        setCurrentTrackIndex(currentTrackIndex + 1);
      } else {
        setPlaying(false);
      }
    };

    audioRef.current.addEventListener("ended", handleSongEnd);

    return () => {
      audioRef.current.removeEventListener("ended", handleSongEnd);
    };
  }, [currentTrackIndex, tracks.length]);

  useEffect(() => {
    if (playing && initialized.current) {
      audioContext.current.resume();
      audioRef.current.play();
    } else {
      audioRef.current.pause();
    }
  }, [playing, currentTrackIndex]);

  const initUserGesture = () => {
    if (initialized.current) {
      return;
    }

    initialized.current = true;
    if (!trackSource.current) {
      trackSource.current = audioContext.current.createMediaElementSource(
        audioRef.current
      );
      trackSource.current.connect(audioContext.current.destination);
    }
  };

  const handlePlayPause = () => {
    initUserGesture();
    const newPlayingState = !playing;
    setPlaying(newPlayingState);
  };

  const handlePlay = () => {
    initUserGesture();
    setPlaying(true);
  };

  const handlePause = () => {
    initUserGesture();
    setPlaying(false);
  };

  const playSong = (trackIndex) => {
    initUserGesture();
    setCurrentTrackIndex(trackIndex);
    setPlaying(true);
  };

  const handleNext = () => {
    initUserGesture();
    if (currentTrackIndex < tracks.length - 1) {
      setCurrentTrackIndex(currentTrackIndex + 1);
    } else {
      setCurrentTrackIndex(0);
    }
  };

  const handlePrevious = () => {
    initUserGesture();
    const currentTime = audioRef.current.currentTime;

    if (currentTime > 1.5) {
      audioRef.current.currentTime = 0;
      return;
    }

    if (currentTrackIndex > 0) {
      setCurrentTrackIndex(currentTrackIndex - 1);
    } else {
      setCurrentTrackIndex(tracks.length - 1);
    }
  };

  const toggleLoop = () => {
    const newLoopState = !loop;
    setLoop(newLoopState);
    audioRef.current.loop = newLoopState;
  };

  const contextValue = {
    tracks,
    currentTrackIndex,
    setCurrentTrackIndex,
    playing,
    setPlaying,
    loop,
    setLoop,
    handlePlayPause,
    handlePlay,
    handlePause,
    handleNext,
    handlePrevious,
    toggleLoop,
    playSong,
    audioRef,
    trackSource,
    audioNode: trackSource.current,
    audioContext,
    initUserGesture
  };

  return (
    <MediaPlayerContext.Provider value={contextValue}>
      {children}
    </MediaPlayerContext.Provider>
  );
};
