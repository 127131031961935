// downloadHelper.js

export const downloadFile = (
  url,
  fileName,
  onProgress,
  onComplete,
  onError
) => {
  const xhr = new XMLHttpRequest();
  xhr.open("GET", url, true);
  xhr.responseType = "blob";

  xhr.onprogress = (event) => {
    if (event.lengthComputable) {
      const percentComplete = (event.loaded / event.total) * 100;
      onProgress(percentComplete, event.loaded, event.total);
    }
  };

  xhr.onload = () => {
    if (xhr.status === 200) {
      const url = window.URL.createObjectURL(xhr.response);
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      onComplete();
    } else {
      onError(new Error(`Download failed: ${xhr.statusText}`));
    }
  };

  xhr.onerror = () => {
    onError(new Error("Download failed"));
  };

  xhr.send();
};
