import React, { useState, useEffect } from "react";
import { fetchLyrics } from "../services/lyrics";
import "./TrackDetails.css";
import { useMediaPlayer } from "../contexts/MediaPlayerContext";

const TrackDetails = ({ track, forWidget, trackIndex }) => {
  const [lyrics, setLyrics] = useState("");
  const [blockWidth, setBlockWidth] = useState(0);
  const { audioNode } = useMediaPlayer();

  useEffect(() => {
    if (!track.lyricsSrc) {
      setLyrics("");
      return;
    }
    fetchLyrics(track.lyricsSrc).then((text) => setLyrics(text));
  }, [track]);

  return (
    <div className={`track-details ${forWidget ? "for-widget" : ""}`}>
      {!forWidget && track.cover && (
        <img
          src={track.cover}
          alt={track.title}
          className="track-details-cover"
        />
      )}
      {!forWidget && <h2 className="track-details-title">{track.title}</h2>}
      {forWidget && (
        <a
          href={`https://piffame.com?trackIndex=${trackIndex}`}
          target="_blank"
          className="track-details-title"
        >
          {track.title}
        </a>
      )}

      <div className="track-details-genre" style={{ color: "#999" }}>
        {track.genre}
      </div>
      {!forWidget && <div className="lyrics">{lyrics}</div>}
    </div>
  );
};

export default TrackDetails;
