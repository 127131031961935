// /src/data/tracks.js
const album = {
  title: "$PIF Grand Video Contest Mixtape",
  artist: "PIF Community",
  // la description doit dire comment participer aux concoours, et ajouter de la motivation egalement et la motivation du jeton PIF
  description: `
🚀 $PIF Grand Video Contest Results 🚀
Thank you to everyone who participated in the $PIF Grand Video Contest! We were thrilled by the creativity and passion displayed in each submission. Every participant did an outstanding job, making it a tough decision for the judges.

Here are the top 5 winners:
1. 🥇 
@Tengekgps - 50M PIF + 3 SOL
2. 🥈 
@cryptomosters - 25M PIF + 2 SOL
3. 🥉 
@elite_art5 - 10M PIF + 1 SOL
4. 
@cryptolegend696 - 10M PIF + 0.5 SOL
5. 
@dhanski1 - 10M PIF + 0.5 SOL
 
Additionally, the following participants will receive rewards for their fantastic contributions:
 
@Rayner565 - 2M PIF + 0.2 SOL
 
@brajacolada - 2M PIF + 0.2 SOL
 
@lordofsol21 - 2M PIF + 0.2 SOL
 
@badmus2121 - 2M PIF + 0.2 SOL
 
@cindeymon - 2M PIF + 0.2 SOL
 
@mae_eat - 2M PIF + 0.2 SOL
 
@TeeposhT - 2M PIF + 0.2 SOL
 
@ToyyibOpeyemi4 - 2M PIF + 0.2 SOL
 
@wazobiadcreator - 2M PIF + 0.2 SOL

🏅 Additionally, a "Frog Fame" Bonus of 10M $PIF will be awarded to the creators of the three most shared videos:
 
@Tengekgps - 10M PIF
 
@badmus2121 - 10M PIF
 
@cindeymon - 10M PIF

All participants will receive a special $PIF gift to claim for their participation.

We are the PIF community, and we are here to stay. Let's continue to spread love, positivity, prosperity, and unity through music and art. Together, we can make the world a better place. Join us on this journey and let's create something beautiful together.

Thank you for being part of the PIF family! 🐸🎥💰 #CryptoContest #PIF #pepewifhat #PIFGiveaway
  `,
  message: `
  We are the PIF community, and we are here to stay. Let's spread love, positivity, prosperity, and unity through music and art. Together, we can make the world a better place. Join us on this journey and let's create something beautiful together.

  #CryptoContest #PIF #PIFGiveaway`,
  cover: "/covers/pif_contest_mixtape_cover.webp",
  backgroundPage: "/covers/pif_contest_mixtape_background.webp"
};

// orders
// smoking_clouds
//

const tracks = [
  {
    title: "Smoking Clouds",
    cover: "",
    genre: "Hip Hop",
    audioSrc: "/audio/smoking_clouds_2.mp3",
    artist: "",
    lyricsSrc: "/lyrics/smoking_clouds.txt",
    isIntro: true
  },
  {
    title: "Got The Vision",
    cover: "",
    genre: "Hip Hop",
    audioSrc: "/audio/got_the_vision_2.mp3",
    artist: "",
    lyricsSrc: "/lyrics/got_the_vision.txt"
  },
  {
    title: "PIF Time",
    cover: "",
    genre: "Hip Hop",
    audioSrc: "/audio/pif_time_3.mp3",
    artist: "",
    lyricsSrc: "/lyrics/pif_time.txt"
  },
  {
    title: "Finding Gold",
    cover: "",
    genre: "Hip Hop",
    audioSrc: "/audio/finding_gold_2.mp3",
    artist: "",
    lyricsSrc: "/lyrics/finding_gold.txt"
  },
  {
    title: "Unity",
    cover: "",
    genre: "Reggae",
    audioSrc: "/audio/unity.mp3",
    artist: "",
    lyricsSrc: "/lyrics/unity.txt"
  },
  {
    title: "Spread The Love",
    cover: "",
    genre: "Reggae",
    audioSrc: "/audio/spread_the_love.mp3",
    artist: "",
    lyricsSrc: "/lyrics/spread_the_love.txt"
  },
  {
    title: "Get Funky",
    cover: "",
    genre: "Funk",
    audioSrc: "/audio/get_funky.mp3",
    artist: "",
    lyricsSrc: "/lyrics/get_funky.txt"
  },
  {
    title: "Bringin' Light",
    cover: "",
    genre: "Funk",
    audioSrc: "/audio/bringin_light.mp3",
    artist: "",
    lyricsSrc: "/lyrics/bringin_light.txt"
  },
  {
    title: "Join The Party",
    cover: "",
    genre: "Funk",
    audioSrc: "/audio/join_the_party.mp3",
    artist: "",
    lyricsSrc: "/lyrics/join_the_party.txt"
  },
  {
    title: "Through Any Weather",
    cover: "",
    genre: "Classical Crossover",
    audioSrc: "/audio/through_any_weather_2.mp3",
    artist: "",
    lyricsSrc: "/lyrics/through_any_weather.txt"
  },
  {
    title: "We're All Friends",
    cover: "",
    genre: "Electro Swing",
    audioSrc: "/audio/we_re_all_frens.mp3",
    artist: "",
    lyricsSrc: "/lyrics/we_re_all_frens.txt"
  },
  {
    title: "Somos Uno",
    cover: "",
    genre: "Latin",
    audioSrc: "/audio/somos_uno.mp3",
    artist: "",
    lyricsSrc: "/lyrics/somos_uno.txt"
  },
  {
    title: "Rock The Stage",
    cover: "",
    genre: "Rock",
    audioSrc: "/audio/rock_the_stage_4.mp3",
    artist: "",
    lyricsSrc: "/lyrics/rock_the_stage.txt"
  },
  // Now remixes
  {
    title: "Smoking Clouds (Variation 1)",
    cover: "",
    genre: "Hip Hop",
    audioSrc: "/audio/smoking_clouds_3.mp3",
    artist: "",
    lyricsSrc: "/lyrics/smoking_clouds.txt",
    isRemix: true
  },
  {
    title: "Smoking Clouds (Variation 2)",
    cover: "",
    genre: "Hip Hop",
    audioSrc: "/audio/smoking_clouds_6.mp3",
    artist: "",
    lyricsSrc: "/lyrics/smoking_clouds.txt",
    isRemix: true
  },
  {
    title: "Smoking Clouds (Variation 3)",
    cover: "",
    genre: "Hip Hop",
    audioSrc: "/audio/smoking_clouds.mp3",
    artist: "",
    lyricsSrc: "/lyrics/smoking_clouds.txt",
    isRemix: true
  },
  {
    title: "Got The Vision (Variation 1)",
    cover: "",
    genre: "Hip Hop",
    audioSrc: "/audio/got_the_vision_3.mp3",
    artist: "",
    lyricsSrc: "/lyrics/got_the_vision.txt",
    isRemix: true
  },
  {
    title: "PIF Time (Variation 1)",
    cover: "",
    genre: "Hip Hop",
    audioSrc: "/audio/pif_time_5.mp3",
    artist: "",
    lyricsSrc: "/lyrics/pif_time.txt",
    isRemix: true
  },
  {
    title: "PIF Time (Variation 2)",
    cover: "",
    genre: "Hip Hop",
    audioSrc: "/audio/pif_time_6.mp3",
    artist: "",
    lyricsSrc: "/lyrics/pif_time.txt",
    isRemix: true
  },
  {
    title: "Finding Gold (Variation 1)",
    cover: "",
    genre: "Hip Hop",
    audioSrc: "/audio/finding_gold_3.mp3",
    artist: "",
    lyricsSrc: "/lyrics/finding_gold.txt",
    isRemix: true
  },
  {
    title: "Unity (Variation 1)",
    cover: "",
    genre: "Reggae",
    audioSrc: "/audio/unity_3.mp3",
    artist: "",
    lyricsSrc: "/lyrics/unity.txt",
    isRemix: true
  },
  {
    title: "Get Funky (Variation 1)",
    cover: "",
    genre: "Funk",
    audioSrc: "/audio/get_funky_3.mp3",
    artist: "",
    lyricsSrc: "/lyrics/get_funky.txt",
    isRemix: true
  },
  {
    title: "Bringin' Light (Variation 1)",
    cover: "",
    genre: "Funk",
    audioSrc: "/audio/bringin_light_2.mp3",
    artist: "",
    lyricsSrc: "/lyrics/bringin_light.txt",
    isRemix: true
  },
  {
    title: "Through Any Weather (Variation 1)",
    cover: "",
    genre: "Classical Crossover",
    audioSrc: "/audio/through_any_weather.mp3",
    artist: "",
    lyricsSrc: "/lyrics/through_any_weather.txt",
    isRemix: true
  },
  {
    title: "We're All Friends (Variation 1)",
    cover: "",
    genre: "Electro Swing",
    audioSrc: "/audio/we_re_all_frens_2.mp3",
    artist: "",
    lyricsSrc: "/lyrics/we_re_all_frens.txt",
    isRemix: true
  },
  {
    title: "Rock The Stage (Variation 1)",
    cover: "",
    genre: "Rock",
    audioSrc: "/audio/rock_the_stage_2.mp3",
    artist: "",
    lyricsSrc: "/lyrics/rock_the_stage.txt",
    isRemix: true
  }
];

export { tracks, album };
