import React, { useEffect, useRef, useState } from "react";
import { tracks, album } from "./data/album";
import "./App.css";
import TrackDetails from "./components/TrackDetails";
import MusicPlayer from "./components/MusicPlayer";
import MediaQuery from "./components/utils/MediaQuery";
import Modal from "./components/utils/Modal";
import { ChromecastProvider } from "./contexts/ChromeCastContext";
import { useSwipeable } from "react-swipeable";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegram } from "react-icons/fa";
import DownloadButton from "./components/utils/DownloadButton";

import {
  MediaPlayerProvider,
  useMediaPlayer
} from "./contexts/MediaPlayerContext";

const getTrackStyle = (track) => {
  const { isIntro, isOutro, isInterlude, isRemix, isBonus } = track;
  let className = "";

  if (isIntro) {
    className += " intro";
  } else if (isOutro) {
    className += " outro";
  } else if (isInterlude) {
    className += " interlude";
  } else if (isRemix) {
    className += " remix";
  } else if (isBonus) {
    className += " bonus";
  }

  return className;
};

const TracksList = ({ tracks, selectTrackIndex, selectedTrackIndex }) => {
  return (
    <div className="tracklist-container">
      <div className="tracklist">
        {tracks.map((track, i) => (
          <div
            key={track.title + " " + i}
            className={`track ${
              selectedTrackIndex === i ? "selected" : ""
            } ${getTrackStyle(track)}`}
            onClick={() => selectTrackIndex(i)}
          >
            {track.cover && (
              <img
                src={track.cover}
                alt={track.title}
                className="track-cover"
              />
            )}
            <span className="track-title">
              {track.title}{" "}
              <span style={{ color: "#999", fontSize: "0.8em" }}>
                - {track.genre}
              </span>
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

function App() {
  const {
    currentTrackIndex: selectedTrackIndex,
    initUserGesture,
    tracks,
    playSong
  } = useMediaPlayer();
  const tabContentRef = useRef(null);
  const appRef = useRef(null);
  const [isWelcomeModalOpen, setIsWelcomeModalOpen] = useState(false);
  const [tab, setTab] = useState("tracks"); // 'details' ou 'tracks'
  const backgroundUrl = album.backgroundPage;

  const isWidgetMode =
    new URLSearchParams(window.location.search).get("widget") === "1";

  console.log("isWidgetMode", isWidgetMode);

  const selectedTrack = tracks[selectedTrackIndex];
  const playedSong = tracks[selectedTrackIndex];

  useEffect(() => {
    if (isWidgetMode) {
      // set div#root height to 100vh
      document.getElementById("root").style.height = "100vh";
    }
  }, []);

  // set background image to app container
  useEffect(() => {
    if (!isWidgetMode) {
      appRef.current.style.backgroundImage = `url(${backgroundUrl})`;
    }
  }, [backgroundUrl]);

  useEffect(() => {
    if (!isWidgetMode) {
      const alreadyVisited_new = localStorage.getItem("alreadyVisited_new");
      if (!alreadyVisited_new) {
        setIsWelcomeModalOpen(true);
        localStorage.setItem("alreadyVisited_new", "true");
      }
    }
  }, []);

  const handleSetTab = (tab) => {
    setTab(tab);
    scrollToTop();
  };

  const selectTrack = (trackIndex) => {
    playSong(trackIndex);
    handleSetTab("details");
  };

  const scrollToTop = () => {
    if (tabContentRef.current) {
      tabContentRef.current.scrollTop = 0;
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSetTab("tracks"),
    onSwipedRight: () => handleSetTab("details"),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true
  });

  const downloadLink =
    "https://storage.googleapis.com/pif-cdn/downloads/PIF-Grand-Video-Contest.zip";
  const downloadName = "PIF-Grand-Video-Contest.zip";

  if (isWidgetMode) {
    return (
      <div className="widget">
        {/* Add album image then add title and author */}
        <div className="album-header">
          <img src={album.cover} alt={album.title} className="album-cover" />
          <a className="album-info" href="https://piffame.com" target="_blank">
            <h1 className="album-title">{album.title}</h1>
            <p style={{ color: "#999" }} className="album-author">
              By <span style={{ fontWeight: "bold" }}>{album.artist}</span>
            </p>
          </a>
        </div>
        {/* Adding track details */}

        <div>
          {selectedTrack && (
            <TrackDetails
              track={selectedTrack}
              forWidget={true}
              trackIndex={selectedTrackIndex}
            />
          )}

          {/* Adding music player */}

          <MusicPlayer />
        </div>
      </div>
    );
  }

  return (
    <div className="app" ref={appRef}>
      <div className="main-content">
        <Modal
          className="welcome-modal"
          isOpen={isWelcomeModalOpen}
          onClose={() => setIsWelcomeModalOpen(false)}
        >
          <div className="welcome-modal-content">
            <img
              src={album.cover}
              alt={album.title}
              className="album-modal-cover"
            />
            <div className="album-modal-text">
              <div className="mt-2 mb-2">
                <h2 className="album-welcome-title">{album.title}</h2>
                <p className="album-welcome-description">{album.description}</p>
                <p className="album-welcome-message">{album.message}</p>
              </div>
              <button
                onClick={async () => {
                  setIsWelcomeModalOpen(false);
                  await initUserGesture();
                  playSong(0);
                }}
              >
                Continue
              </button>
            </div>
          </div>
        </Modal>

        <MediaQuery rules={{ maxWidth: 958 }}>
          <>
            <div className="album-header">
              <img
                src={album.cover}
                alt={album.title}
                className="album-cover"
              />
              <div className="player-overlay">
                {playedSong && (
                  <>
                    <h1 className="current-track-title">
                      {selectedTrack.title}
                    </h1>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center"
                      }}
                    >
                      <div>
                        <p style={{ color: "#999" }}>
                          By{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {album.artist}
                          </span>
                        </p>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          gap: "10px"
                        }}
                      >
                        <a
                          href="https://x.com/pepewifcoinsol"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "#fff",
                            fontSize: "1.5em",
                            cursor: "pointer"
                          }}
                        >
                          <FaXTwitter />
                        </a>
                        <a
                          href="https://t.me/pifentry"
                          target="_blank"
                          rel="noopener noreferrer"
                          style={{
                            color: "#fff",
                            fontSize: "1.5em",
                            cursor: "pointer"
                          }}
                        >
                          <FaTelegram />
                        </a>
                      </div>
                    </div>
                  </>
                )}
                <MusicPlayer />
              </div>
            </div>

            <div className="tab-buttons">
              <button onClick={() => handleSetTab("details")}>Details</button>
              <button onClick={() => handleSetTab("tracks")}>Tracks</button>

              <DownloadButton
                url={downloadLink}
                fileName={downloadName}
                width="34px"
                height="34px"
                srcLoaded={"/loader_image.png"}
                srcNotLoaded={"/loader_image_bw.png"}
                onStart={() => console.log("Download started")}
                onProgress={(percentComplete, loaded, total) =>
                  console.log(
                    `Downloading: ${Math.round(percentComplete)}% - ${(
                      loaded /
                      1024 /
                      1024
                    ).toFixed(2)} MB / ${(total / 1024 / 1024).toFixed(2)} MB`
                  )
                }
                onComplete={() => console.log("Download completed")}
                onError={(error) => console.error(error)}
                className={"btn-1"}
              >
                <span style={{ fontWeight: "bold" }}>Download</span>
              </DownloadButton>
            </div>

            <div {...handlers} className="tab-content" ref={tabContentRef}>
              {tab === "details" && selectedTrack && (
                <TrackDetails track={selectedTrack} />
              )}
              {tab === "tracks" && (
                <TracksList
                  selectedTrackIndex={selectedTrackIndex}
                  tracks={tracks}
                  selectTrackIndex={selectTrack}
                />
              )}
            </div>
          </>
        </MediaQuery>

        <MediaQuery rules={{ minWidth: 959 }}>
          <>
            <div className="left-sidebar">
              <div className="album-header">
                <img
                  src={album.cover}
                  alt={album.title}
                  className="album-cover"
                />
                <h1 className="album-title">{album.title}</h1>
                <p style={{ color: "#999" }}>
                  By <span style={{ fontWeight: "bold" }}>{album.artist}</span>
                  <br />
                  {/* twitter icon link */}
                </p>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "20px",
                    gap: "10px"
                  }}
                >
                  <a
                    href="https://x.com/pepewifcoinsol"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#fff",
                      fontSize: "1.5em",
                      cursor: "pointer"
                    }}
                  >
                    <FaXTwitter />
                  </a>

                  <a
                    href="https://t.me/pifentry"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      color: "#fff",
                      fontSize: "1.5em",
                      cursor: "pointer"
                    }}
                  >
                    <FaTelegram />
                  </a>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                  <DownloadButton
                    url={downloadLink}
                    fileName={downloadName}
                    width="50px"
                    height="50px"
                    srcLoaded={"/loader_image.png"}
                    srcNotLoaded={"/loader_image_bw.png"}
                    onStart={() => console.log("Download started")}
                    onProgress={(percentComplete, loaded, total) =>
                      console.log(
                        `Downloading: ${Math.round(percentComplete)}% - ${(
                          loaded /
                          1024 /
                          1024
                        ).toFixed(2)} MB / ${(total / 1024 / 1024).toFixed(
                          2
                        )} MB`
                      )
                    }
                    onComplete={() => console.log("Download completed")}
                    onError={(error) => console.error(error)}
                    className={"cta-1"}
                  >
                    {/* Download And Participate */}
                    Download
                  </DownloadButton>

                  {/* <a
                    href={downloadLink}
                    download={downloadName}
                    className="btn-1"
                    style={{ textDecoration: "none" }}
                  >
                    Download
                  </a> */}
                </div>
              </div>
              <MusicPlayer />

              <TracksList
                tracks={tracks}
                selectTrackIndex={selectTrack}
                selectedTrackIndex={selectedTrackIndex}
              />
            </div>
            <div className="right-content">
              {selectedTrack && <TrackDetails track={selectedTrack} />}
            </div>
          </>
        </MediaQuery>
      </div>
    </div>
  );
}

export default () => (
  <MediaPlayerProvider currentTracks={tracks}>
    <App />
  </MediaPlayerProvider>
);
