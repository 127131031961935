// DownloadButton.js

import React, { useState } from "react";
import { downloadFile } from "../../services/download";
import ImageLoaderEffect from "./ImageLoaderEffect";

const DownloadButton = ({
  url,
  fileName,
  onStart,
  onProgress,
  onComplete,
  onError,
  children,
  className,
  srcNotLoaded,
  srcLoaded,
  width = "200px",
  height = "50px"
}) => {
  const [downloading, setDownloading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [loaded, setLoaded] = useState(0);
  const [total, setTotal] = useState(0);

  const handleDownload = () => {
    setDownloading(true);
    if (onStart) onStart();

    downloadFile(
      url,
      fileName,
      (percentComplete, loaded, total) => {
        setProgress(percentComplete);
        setLoaded(loaded);
        setTotal(total);
        if (onProgress) onProgress(percentComplete, loaded, total);
      },
      () => {
        setDownloading(false);
        setProgress(100);
        if (onComplete) onComplete();
      },
      (error) => {
        setDownloading(false);
        if (onError) onError(error);
      }
    );
  };

  return (
    <div>
      {!downloading && (
        <button
          onClick={handleDownload}
          disabled={downloading}
          className={className || ""}
        >
          {children}
        </button>
      )}
      {downloading && (
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <ImageLoaderEffect
            srcNotLoaded={srcNotLoaded}
            srcLoaded={srcLoaded}
            progress={progress}
            width={width}
            height={height}
          />
          <p style={{ fontSize: "0.8em", margin: 0, color: "#b3b3b3" }}>
            {(((loaded / 1024 / 1024) * 100) / 100).toFixed(2)} MB / <br />
            {(((total / 1024 / 1024) * 100) / 100).toFixed(2)} MB
          </p>
        </div>
      )}
    </div>
  );
};

export default DownloadButton;
