export const fetchLyrics = async (lyricsPath) => {
  try {
    const response = await fetch(lyricsPath);
    if (!response.ok) throw new Error("Network response was not ok.");
    const text = await response.text();
    return text;
  } catch (error) {
    console.error("Error fetching lyrics:", error);
    return "Lyrics not available.";
  }
};
