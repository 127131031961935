import React, { useState, useEffect, useRef } from "react";
import {
  FaPlay,
  FaPause,
  FaForward,
  FaBackward,
  FaCircleNotch
} from "react-icons/fa";
import { IoIosRepeat } from "react-icons/io"; // Icône de répétition de style iOS
import "./MusicPlayer.css";
import { useMediaPlayer } from "../contexts/MediaPlayerContext";

const MusicPlayer = ({}) => {
  const {
    tracks,
    currentTrackIndex,
    playing,
    handlePlayPause,
    handlePrevious,
    handleNext,
    toggleLoop,
    loop,
    audioRef,
    trackSource,
    audioBuffers,
    loadingAudioBuffers
  } = useMediaPlayer();

  const musicPlayerRef = useRef(null);

  return (
    <div className="music-player" ref={musicPlayerRef}>
      <div className="player-controls">
        {playing ? (
          <FaPause className="control-icon" onClick={handlePlayPause} />
        ) : (
          <FaPlay className="control-icon" onClick={handlePlayPause} />
        )}
        <FaBackward
          className={`control-icon ${
            currentTrackIndex === 0 ? "disabled" : ""
          }`}
          onClick={handlePrevious}
        />
        <FaForward
          className={`control-icon ${
            currentTrackIndex === tracks.length - 1 ? "disabled" : ""
          }`}
          onClick={handleNext}
        />
        <div style={{ position: "relative" }}>
          <IoIosRepeat
            className={`control-icon ${loop ? "active" : ""}`}
            onClick={toggleLoop}
          />
          <span className={`loop-icon-number ${loop ? "active" : ""}`}>1</span>
        </div>
      </div>
      {/* {!loadingAudioBuffers && !!musicPlayerWidth && (
        <TrackVisualizer
          audioBuffer={selectedAudioBuffer}
          width={musicPlayerWidth}
          audioRef={audioRef}
          height="50"
          audioNode={trackSource.current}
          onSeek={handleSeek}
          drawingLoader={<FaCircleNotch className="circle-loader-icon" />}
        />
      )} */}
      {/* {loadingAudioBuffers && (
        <FaCircleNotch className="circle-loader-icon rotate" />
      )} */}
    </div>
  );
};

export default () => {
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setTimeout(() => {
      setLoaded(true);
    }, 2000);
  }, []);

  return (
    <div className="music-player-container">
      {loaded && <MusicPlayer />}
      {!loaded && (
        <div style={{ textAlign: "center" }}>
          <FaCircleNotch className="circle-loader-icon rotate" />
        </div>
      )}
    </div>
  );
};
