// ImageLoaderEffect.js

import React from "react";
import PropTypes from "prop-types";
import "./ImageLoaderEffect.css";

const ImageLoaderEffect = ({
  srcNotLoaded,
  srcLoaded,
  progress,
  width = "inherit",
  height = "100%"
}) => {
  return (
    <div
      className="image-loader-container"
      style={{
        width,
        height,
        position: "relative"
      }}
    >
      <div
        className="image-loader"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundImage: `url(${srcNotLoaded})`,
          backgroundSize: "cover",
          zIndex: 1
        }}
      />
      <div
        className="image-loaded"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          backgroundImage: `url(${srcLoaded})`,
          backgroundSize: "cover",
          clipPath: `inset(${100 - progress}% 0 0 0)`,
          zIndex: 2
        }}
      />
    </div>
  );
};

ImageLoaderEffect.propTypes = {
  srcNotLoaded: PropTypes.string.isRequired,
  srcLoaded: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
  width: PropTypes.string.isRequired,
  height: PropTypes.string.isRequired
};

export default ImageLoaderEffect;
