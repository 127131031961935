import React, { useState, useEffect } from "react";

const MediaQuery = ({ children, rules }) => {
  const [isVisible, setIsVisible] = useState(false);

  const updateVisibility = () => {
    const screenWidth = window.innerWidth;
    const meetsCriteria = Object.entries(rules).every(([rule, value]) => {
      switch (rule) {
        case "minWidth":
          return screenWidth >= value;
        case "maxWidth":
          return screenWidth <= value;
        default:
          return true;
      }
    });
    setIsVisible(meetsCriteria);
  };

  useEffect(() => {
    updateVisibility();
    window.addEventListener("resize", updateVisibility);
    return () => window.removeEventListener("resize", updateVisibility);
  }, [rules]);

  return isVisible ? <>{children}</> : null;
};

export default MediaQuery;
